<i18n>
{
  "de": {
    "pageTitle": "Szenarien"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <template #default>
      <ScenariosSection :portfolio="portfolio" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ScenariosSection from '@/components/scenarios/ScenariosSection.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'scenarios',

  mixins: [
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    ScenariosSection,
    PageTitle,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
