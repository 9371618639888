<i18n>
{
  "de": {
    "duplicate": "Duplizieren (inkl. Benutzer definierten Szenario Massnahmen)"
  }
}
</i18n>

<template>
  <VPopover trigger="hover" placement="bottom">
    <ListButton :img-src="'/icons/copy-plus.svg'" :disabled="disabled" @click="onClick()" />
    <template slot="popover">
      <main>{{ tooltip ? tooltip : $t('duplicate') }}</main>
    </template>
  </VPopover>
</template>

<script>
import ListButton from '@/components/shared/lists/ListButton.vue'
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
    ListButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
